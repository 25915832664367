.container {
  padding: 48px;

  border-radius: 16px;
  background: var(--palette-default);
}

.container .form {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  max-width: 1200px;
  margin: 0 auto;
}

.fileList {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  gap: 10px;
}

.actions :global(.checkbox__label) {
  max-width: 300px;

  white-space: normal !important;
  word-wrap: break-word;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
}

.example {
  display: flex;
  justify-content: center;
}

.example img {
  max-width: 100%;
}

.checkbox {
  gap: 12px;
}

.additionalInfo {
  color: var(--palette-text-default);
}

.additionalInfo span {
  font-weight: 500;
}

.updates {
  margin-bottom: 18px;
  padding: 0 24px;

  h4 {
    margin: 0 0 8px 0;
  }
}

.databasesUpdates {
  display: grid;
  grid-template-columns: 300px 300px;

  flex-wrap: wrap;
  column-gap: 24px;
}

.updateParagraph {
  margin: 0;

  color: var(--palette-grey-active);

  font-size: 12px;
}

@media (max-width: 992px) {
  .fileList {
    grid-template-columns: 100%;
  }

  .example {
    font-size: 26px;
  }

  .databasesUpdates {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 24px;
  }
}

@media (max-width: 400px) {
  .container {
    padding: 20px 14px;
  }
}
